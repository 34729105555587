import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import "./src/styles/shared.css";
import "aos/dist/aos.css";

import React, { useEffect } from "react";
import AOS from "aos";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from "@remix-run/react";

import * as gtag from "./src/helpers/google-analytics";
import { Footer } from "./src/components/molecules/footer";
import theme from "./src/theme";
import { MuiProvider } from "./src/theme/provider";

export const meta: MetaFunction = () => [
  {
    name: "theme-color",
    content: theme.palette.primary.main,
  },
];

export const links: LinksFunction = () => [
  {
    rel: "preconnect",
    href: "https://fonts.googleapis.com",
  },
  {
    rel: "preconnect",
    crossOrigin: undefined,
    href: "https://fonts.gstatic.com",
  },
  {
    rel: "stylesheet",
    type: "text/css",
    charSet: "UTF-8",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
  },
  {
    rel: "stylesheet",
    type: "text/css",
    charSet: "UTF-8",
    href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css",
  },
  {
    rel: "stylesheet",
    type: "text/css",
    charSet: "UTF-8",
    href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css",
  },
];

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const gaTrackingId = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID;

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <html lang="es">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD3euurZRuYXIG7uWZ98NBVjuSaRbTwcaI&loading=async&libraries=places"
          async
        />
        {process.env.NODE_ENV === "development" || !gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
      </head>
      <body style={{ margin: 0 }}>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};

export function ErrorBoundary() {
  const error = useRouteError();
  const location = useLocation();
  const data = isRouteErrorResponse(error)
    ? {
        heading: "Página no encontrada",
        message:
          "Lo sentimos, pero la página que estás buscando no existe. Es posible que hayas ingresado una URL incorrecta.",
        image: "not-found.png",
        url: location?.pathname?.split?.("/")?.slice?.(0, -1)?.join?.("/"),
      }
    : {
        heading: "Ups!",
        message:
          "Ha ocurrido un error inesperado. Por favor, intenta nuevamente más tarde.",
        image: "unexpected-error.png",
      };

  captureRemixErrorBoundaryError(error);

  return (
    <MuiProvider>
      <Stack sx={styles.container}>
        <Container sx={styles.content}>
          <Stack alignItems="center" gap={3}>
            <Box component="img" src={`/${data?.image}`} sx={styles.image} />
            <Typography variant="h4" align="center" sx={styles.heading}>
              {data?.heading}
            </Typography>
            <Typography align="center" sx={styles.message}>
              {data?.message}
            </Typography>
            {!!data?.url && (
              <Stack direction="row" justifyContent="center">
                <Link to={data?.url}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={styles.actionButton}
                  >
                    Volver al inicio
                  </Button>
                </Link>
              </Stack>
            )}
          </Stack>
        </Container>
        <Footer />
      </Stack>
    </MuiProvider>
  );
}

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <CssBaseline />
      <Outlet />
    </>
  );
}

export default withSentry(App);

export function HydrateFallback() {
  return (
    <Box sx={styles.fallbackContainer}>
      <Skeleton variant="rectangular" sx={styles.skeletonBanner} />
      <Skeleton variant="circular" sx={styles.skeletonAvatar} />
    </Box>
  );
}

const styles = {
  fallbackContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    position: "relative",
    width: "100%",
  },
  skeletonBanner: {
    width: "100%",
    height: "auto",
    paddingTop: "37.5%", // Mantiene la proporción 1920x720 (37.5% de 1920 es 720)
  },
  skeletonAvatar: {
    backgroundColor: "lightgrey",
    width: {
      xs: 121,
      md: 185,
    },
    height: {
      xs: 121,
      md: 185,
    },
    mt: {
      xl: -13,
      lg: -13,
      md: -13,
      sm: -10,
      xs: -7,
    },
    ml: {
      xs: "16px",
      md: "40px",
    },
  },
  container: {
    height: "100vh",
  },
  image: {
    objectFit: "contain",
    maxHeight: 470,
    height: "100%",
    width: "100%",
  },
  content: {
    flex: 1,
    py: 8,
  },
  heading: {
    color: "#3F3E56",
    fontWeight: 500,
  },
  message: {
    maxWidth: 700,
    color: "rgba(136, 136, 136, 1)",
  },
  actionButton: {
    backgroundColor: "#25C05A",
    borderRadius: "4px",
    color: "#FFFFFF",
    padding: 2,
    "&:hover": {
      backgroundColor: "#25C05A",
    },
  },
};